
.room-price{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.room-price>p{
    font-size: 30px;
    margin: 0;
}

.room-packages-price-box{
    padding-bottom: 5px;
}

.price-card-room{
    transition: .2s;
}
.price-card-room:hover{
    transform: scale(1.02);
}

.edit-date-pickers{
    display: flex;
    justify-content: space-around;
}

.edit-date-pickers-title{
    margin: 0;
    padding: 0;
}