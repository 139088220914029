.rooms {
    position: absolute;

    left: 250px;
    right: 0;


    padding: 50px;

    background-color: rgb(250, 250, 250);

}


.room {
    position: relative;
    background-color: rgb(248, 248, 248);
}

.room-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    gap: 10px;
}

.room-group {

    background-color: rgb(255, 255, 255);
}


.images-display{
    display: flex;
    justify-content: space-between;
}