.dashboard {
    position: absolute;
    left: 250px;
    background-color: rgb(250, 250, 250);
    padding: 50px;

    right: 0;

}

.box {
    /* background-color: rgb(192, 53, 53); */
    width: 100%;
    /* height: 100px; */
    display: flex;
    justify-content: space-evenly;
}

.box-item {
    width: 250px;
    height: 100px;
    background-color: rgb(214, 214, 214);
    border-radius: 10px;
}

._booking-table-box {
    border-radius: 10px;
    border: 1px solid rgb(224, 224, 224);
    padding: 10px;
    background-color: white;
    width: 100%;
    overflow-x: auto;
    font-size: 13px;
    margin-bottom: 0px;
}

._booking-table {
    min-width: 800px;
}

._booking-table-box-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

._booking-table-box-bar-show {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;

    border: 1px solid rgb(216, 216, 216);
    padding: 4px 6px;
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
}