.room-images {
    position: absolute;

    left: 250px;
    right: 0;


    padding: 50px;

    background-color: rgb(250, 250, 250);

}

.room-images-box {

    text-align: center;

}

.image-box {
    aspect-ratio: 16/9 !important;

    display: inline-block;
    width: fit-content;
    padding: 0px;
    margin: 10px;
    border-radius: 0;

    position: relative;
}


.close-btn-box{
    /* background-color: aqua; */
    display: flex;
    justify-content: right;
    align-items: center;
}

.close-btn {
    /* position: absolute;
    top: 8px;
    right: 8px; */
    /* text-align: right; */

    color: white;

    background-color: white;
    border-radius: 50%;
    transform: scale(0.8);
    transition: 0.3s;
}

.close-btn:hover {
    transform: scale(0.9);
    background-color: rgb(255, 237, 237);
}

.image-upload {
    display: none;
}

.image-upload.show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.room-imgs {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.image-upload-img {


    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}