.login{
    background-color: rgb(243, 243, 243);

    position: fixed;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;

}
.title-login{
    position: fixed;
    top: 30px;
    left: 30px;
    font-size: 34px;
    color: rgb(10, 148, 63);
    font-weight: 400;
}

.login-form{
    background-color: rgb(255, 255, 255);
    padding: 40px;

    color: black;

    border-radius: 10px;
    border: 1px solid rgb(226, 226, 226);

}



.login-title-box{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.submit_btn-login{
    float: right;
}

.input-box{
    background-color: rgb(250, 250, 250);
}

.invalid-data-all{
    display: none;
}

.invalid-data-all.invalid{
    text-align: center;
    display: block;
    font-size: 12px;
    color: red;
    font-weight: 200;
}

.invalid-data-username{
    display: none;
}
.invalid-data-username.invalid{
    display: block;
    font-size: 12px;
    color: red;
}

.invalid-data-password{    
    display: none;
}

.invalid-data-password.invalid{
    display: block;
    font-size: 12px;
    color: red;
}