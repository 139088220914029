.sidebar {

    padding-top: 20px;

    position: fixed;
    width: 250px;
    top: 0;
    bottom: 0;

    /* border-right: 1px solid rgb(228, 228, 228); */

    background-color: rgb(0, 7, 75);
    overflow:auto;
    overflow-x: hidden;

    color: white;
}

._btn {
    height: 40px;
    border-radius: 0px;
    border: none;
    text-align: start;
    padding-left: 60px;
    color: black;
    font-size: 16px !important;
    color: white;

    
    margin-left: 45px;
    padding-left: 13px;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
}
._btn:hover{
    background-color: gray;
}

._btn.active {
    /* background-color: #e8f7ff; */
    background-color: rgb(250, 250, 250);
    color: #3d5fff;
    /* border-right: 3px solid #3d5fff; */
}

.sidebar-menu-title{
    /* border-left: 1px solid blue;
    border-bottom: 1px solid blue; */
    padding-left: 10px;
    font-size: 12px;
}

.profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 10px; */

    margin-left: 40px;
    margin-right: 40px;

}