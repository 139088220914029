.facilities {
    position: absolute;

    left: 250px;
    right: 0;


    padding: 50px;

    background-color: rgb(250, 250, 250);

}

._btn-facility{
    gap: 10px;
}

.add-title-box{
    align-items: center;
    gap: 20px;
}

.placeholder-facilities{
    height: 40px;
}

.edit-text{
    color: black;
    font-size: 16px;
    font-weight: 500;
}

.modal-body-edit{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal-body-edit-img{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.modal-body-delete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.delete-text{
    font-weight: 400;
    color: black;
font-size: 14px;

}

.delete-text>span{
    font-weight: 600;
}