.calendarview{
    position: absolute;
    left: 250px;
    background-color: rgb(250, 250, 250);
    padding: 50px;

    right: 0;
    top: 0;
    bottom: 0;

}

.calender-box{
    display: flex;
    margin: 10px;

}

.calender-header-box-acc{

    
    /* width: 34px; */
    height: 60px;
    /* min-height: 50px; */
    /* background-color: red; */
    /* width: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.calender-c-box{
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
}

.calender-custom{
    display: flex;
    gap: 10px;
    justify-content: start;
    align-items: center;
}

.calender-header-box-acc-card{
    height: 60px !important;
    /* width: 100px !important; */
    /* height: 50px; */
   
    padding: 0 !important;


    transition: 0.3s;
    background-color: rgb(221, 221, 221);

}

/* .calender-header-box-acc-card:hover{
    background-color: red;

    
    transform: scale(1.1);;
} */

.calender-header-box-acc-data{
    display: flex;
    justify-content: start;
    align-items: center;
}

.calender-data-side-left{
    margin-top: 10px;
    height: 40px;
    width: 24px;
    border: 1px solid gray;
    
    border-left: none;
    border-radius: 0 5px 5px 0;
}
.calender-data-side-right{
    position: relative;
    margin-top: 10px;
    margin-left: 2px;
    height: 40px;
    width: 24px;
    border: 1px solid gray;
    border-right: none;
    border-radius: 5px 0 0 5px;
    /* align-items: center; */
}
.calender-data-side-full{
    margin-top: 10px;
    height: 40px;
    width: 50px;
    border: 1px solid gray;
    border-right: none;
    border-left: none;
}

.calender-data-side-order{
    position: absolute;
    left: 2px;
    width: fit-content;
    margin-top: 10px;
    margin-left: 4px;
    font-size: small;
    /* z-index: 200; */
    
}

.calender-data-side-both{
    /* position: relative;
    margin-top: 10px;
    margin-left: 2px;
    height: 40px;
    width: 24px;
    border: 1px solid gray;
    border-right: none;
    border-radius: 5px 0 0 5px; */

    /* margin-top: 10px;
    height: 40px;
    width: 24px;
    border: 1px solid gray;
    
    border-left: none;
    border-radius: 0 5px 5px 0; */

    margin: 10px 0 0 0;
    height: 40px;
    width: 50px;

    /* border-right: none;
    border-left: none; */

    /* background-color: blueviolet; */

    display: flex;
    justify-content: space-between;
    gap: 0;
}

.calender-data-side-both-end{
    height: 40px;
    width: 19px;

    border: 1px solid gray;
    border-left: none;
    border-radius: 0 5px 5px 0; 
}


.calender-data-side-both-start{
    position: relative;
    height: 40px;
    width: 19px;

    border: 1px solid gray;
    border-right: none;
    border-radius: 5px 0 0 5px;
}

.calander-header-view{
    
}

.calander-header-item{

}