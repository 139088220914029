.image-box-select{
    transition:  0.3s;
}

.image-box-select:hover{
    transform: scale(1.05);
}


.image-select-footer{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 15px;
}
