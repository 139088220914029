.offers-items-box{
    background-color: white ;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 10px 0;
}


.offers-titles-box{
    margin-left: 10px;
    display: flex;
     justify-content: start;
     align-items: center;
     gap: 20px;
/* background-color: rgb(173, 176, 255); */
    }

